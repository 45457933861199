import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import LivingLogo from './img/default-monochrome_living_logo.svg';
import HouseIcon from './img/house-svgrepo-com.svg';
import ProfileIcon from './img/profile-circle-svgrepo-com.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Navigation.css';


const Navigation = ({ changeLogInState, isLoggedIn }) => {
  return (
    <>
      {['md'].map((expand) => (
        <Navbar key={expand} expand={expand} sticky="top" className="mb-3">
          <Container fluid>
            <Navbar.Brand href="#">
              <img src={LivingLogo} alt="Living" width="24" height="24" /> Living
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Living
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content flex-grow-1">
                  <Nav.Link href="#dashboard">Dashboard</Nav.Link>
                  {/* <Nav.Link href="#power">Power</Nav.Link>
                  <Nav.Link href="#water">Water</Nav.Link>
                  <Nav.Link href="#gas">Gas</Nav.Link> */}
                  <NavDropdown
                    title="Energy"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#power">Power</NavDropdown.Item>
                    <NavDropdown.Item href="#water">Water</NavDropdown.Item>
                    <NavDropdown.Item href="#gas">Gas</NavDropdown.Item>
                    {/* <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item> */}
                  </NavDropdown>
                  <Nav.Link href="#finance">Finance</Nav.Link>
                </Nav>

                {isLoggedIn ? (
                  <>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                      <NavDropdown
                        title={<>
                          <img src={HouseIcon} alt="Living" width="22" height="22" className='me-1 pb-1' />
                          Heimatstraße
                        </>}
                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                        drop='down-centered'
                      >
                        <NavDropdown.Item href="#home2">Alter Weg</NavDropdown.Item>
                        <NavDropdown.Item href="#home2">Zweite Allee</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#homesettings">
                          Home Settings
                        </NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown
                        title={<>
                          <img src={ProfileIcon} alt="Living" width="22" height="22" />
                        </>}
                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                        align='end'
                      >
                        {/* <NavDropdown.Item href="#action3"></NavDropdown.Item>
                        <NavDropdown.Divider /> */}
                        <NavDropdown.Item href="#profilesetting">Profile Settings</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='no-hover'>
                          <Form className="d-flex justify-content-md-center">
                            <Button
                              onClick={() => changeLogInState(false)}
                              size='sm'
                              variant="outline-danger">Logout</Button>
                          </Form>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>

                    {/* <Form className="d-flex align-items-center">
                  <Button
                  onClick={() => changeLogInState(false)}
                  className="btn btn-sm"
                  variant="outline-danger">Logout</Button>
                </Form> */}
                  </>
                ) : (
                  <>
                    {/* <Nav className="justify-content-end flex-grow-1 pe-3">
                      <NavDropdown
                        title="Dropdown"
                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                      >
                        <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action4">
                          Another action
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action5">
                          Something else here
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav> */}

                    <Form className="d-flex align-items-center">
                      <Form.Control
                        type="text"
                        placeholder="User or E-Mail"
                        id="input-user"
                        className="me-2 input-sm"
                        size="sm"
                        aria-label="Username"
                      />
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        id="input-pwd"
                        className="form-control form-control-sm me-2 input-sm"
                        aria-label="Password"
                      />
                      <Button
                        onClick={() => changeLogInState(true)}
                        size='sm'
                        variant="outline-success">Login</Button>
                    </Form>

                  </>
                )}

              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navigation;