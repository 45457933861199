import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Navigation from './components/Navigation/Navigation';
import MyLineChart from './components/MyChart/MyLineChart';
import MyBarChart from './components/MyChart/MyBarChart';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

class App extends Component {
  constructor() {
    super();
    this.state = {
      input: '',
      imageUrl: '',
      box: {},
      route: 'signin',
      isLoggedIn: false,
      user: {
        id: '',
        name: '',
        email: '',
        entries: 0,
        joined: ''
      }
    }
  }

  changeLogInState = (isLoggedIn) => {
    if (isLoggedIn === true) {
      this.setState({ isLoggedIn: true });
    } else if ((isLoggedIn === false)) {
      this.setState({ isLoggedIn: false });
    }
  }


  render() {
    return (
      <div className="App">
        {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}

        <Navigation isLoggedIn={this.state.isLoggedIn} changeLogInState={this.changeLogInState} />

        <div className="container text-center d-flex align-items-center justify-content-center bg-image">

        </div>

        {/* <div className="container text-center d-flex align-items-center justify-content-center bg-image" style="height: calc(100% - 56px);">
        <div className="row">
            <div className="buffer col-12"></div>
            <section id="btn-xl-wrapper" className="col">
                <a href="#"><button className="btn btn-primary btn-xl">Power</button></a>
                <a href="#"><button className="btn btn-primary btn-xl">Water</button></a>
                <a href="#"><button className="btn btn-primary btn-xl">Gas</button></a>
                <br/>
                <a href="#"><button className="btn btn-secondary btn-xl animate__animated animate__zoomIn">Sign Up</button></a>
            </section>
        </div>
    </div> */}

        {this.state.isLoggedIn ? (
          <Container fluid>
            <Row className='mb-4'>
              <Col xl={10} className='mb-4'><div className='containerbox h-100'><MyLineChart /></div></Col> 
              <Col md={2} className='mb-4'><div className='containerbox h-100'>Filter-Opts:<br/><br/>Absolut (Line)<br/>Ableitung<br/>Monatlich (Bar)<br/><br/>Tag/Monat/Jahr<br/><br/>Zeitraum (Kalender)</div></Col>
              <Col lg className='mb-4'><div className='containerbox h-100'><h1>1600 kWh/year</h1></div></Col>
              <Col md={2} className='mb-4'><div className='containerbox h-100'>Test 4</div></Col>
              <Col md={4} className='mb-4'><div className='containerbox h-100'>Test 5</div></Col>
              <Col xl={10} className='mb-4'><div className='containerbox h-100'><MyBarChart /></div></Col> 
              <Col md={2} className='mb-4'><div className='containerbox h-100'>Test 7</div></Col>
            </Row>
          </Container>
        ) : (
          <Container fluid></Container>
        )}

      </div>
    );
  }
}

export default App;
